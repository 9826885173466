import React from "react";

const BlankPage = ({ content }) => {
  return (
    <div className="h-screen w-full bg-gray flex items-center justify-center ">
      {content && <h1 className="font-medium text-slate-600">{content}</h1>}
    </div>
  );
};

export default BlankPage;
