import React, { useEffect, useState } from "react";
import BlankPage from "./BlankPage";
import { useSearchParams } from "react-router-dom";
import { googleAuth } from "../api";
import SuccessPage from "./SuccessPage";

const Authenticate = () => {
  const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const GOOGLE_CLIENT_SECRET = process.env.REACT_APP_GOOGLE_CLIENT_SECRET;

  const [searchParam] = useSearchParams();
  const googleAuthCode = searchParam.get("code");
  const [status, setStatus] = useState("Authenticating User...");

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        setStatus("Fetching tokens from Google...");

        const data = new URLSearchParams({
          code: googleAuthCode,
          client_id: GOOGLE_CLIENT_ID,
          client_secret: GOOGLE_CLIENT_SECRET,
          redirect_uri: window.location.origin,
          grant_type: "authorization_code",
        });

        const response = await fetch("https://oauth2.googleapis.com/token", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: data,
        });

        const tokens = await response.json();

        if (tokens?.id_token) {
          setStatus("Validating tokens with server...");
          const googleResponse = await googleAuth({
            access_token: tokens.id_token,
          });

          if (googleResponse?.status === 200) {
            const accessToken = googleResponse?.data?.results?.data?.access;
            const refreshToken = googleResponse?.data?.results?.data?.refresh;

            localStorage.setItem("USER_ACCESS_TOKEN", accessToken);
            localStorage.setItem("USER_REFRESH_TOKEN", refreshToken);

            // Encode the tokens to ensure they are properly formatted for the URL
            const encodedAccessToken = encodeURIComponent(accessToken);
            const encodedRefreshToken = encodeURIComponent(refreshToken);
            setStatus("Auth-success");
            window.location.href = `timetracker://${encodedAccessToken}/${encodedRefreshToken}`;

            const backTo = sessionStorage.getItem("backTo");
            if (backTo) {
              sessionStorage.removeItem("backTo");
              window.location.href = `${
                window.location.origin
              }${decodeURIComponent(backTo)}`;
            }
          } else if (googleResponse?.status === 404) {
            setStatus("User not found!");
            console.error("User not found!");
          } else if (googleResponse?.status === 400) {
            setStatus("User not found!");
            console.error("User not found!");
          }
        } else {
          setStatus("Failed to retrieve tokens from Google");
          console.error("Failed to retrieve tokens from Google");
        }
      } catch (error) {
        setStatus("Failed to login with Google");
        console.error("Failed to login with Google", error);
        window.location.href = `${window.location.origin}`;
      }
    };

    if (googleAuthCode) {
      fetchTokens();
    } else {
      setStatus("Redirecting to Google authentication...");
      const params = new URLSearchParams({
        response_type: "code",
        client_id: GOOGLE_CLIENT_ID,
        scope:
          "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
        redirect_uri: window.location.origin,
        prompt: "select_account",
      });
      window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?${params.toString()}`;
    }
  }, [googleAuthCode]);
  if (status === "Auth-success") {
    return <SuccessPage />;
  }
  return <BlankPage content={status} />;
};

export default Authenticate;
